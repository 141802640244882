import { NotificationType } from "./domain";

export const fakePingConfigs = [
  {
    id: "1",
    uid: "user1",
    url: "https://wallish.com",
    interval: 300,
    saveResponseOnError: true,
    notifyOnError: true,
    notifications: [
      { type: NotificationType.EMAIL, target: "user1@example.com" },
      { type: NotificationType.SMS, target: "+1234567890" },
    ],
    createdAt: "2024-10-01T12:00:00Z",
    updatedAt: "2024-10-02T08:00:00Z",
  },
  {
    id: "2",
    uid: "user2",
    url: "https://brownie.com",
    interval: 600,
    saveResponseOnError: false,
    notifyOnError: true,
    notifications: [
      {
        type: NotificationType.WEBHOOK,
        target: "https://webhook.example.com/notify",
      },
    ],
    createdAt: "2024-10-03T09:30:00Z",
  },
  {
    id: "3",
    uid: "user3",
    url: "https://allahuakbar.com",
    interval: 1200,
    saveResponseOnError: true,
    notifyOnError: false,
    notifications: [{ type: NotificationType.PUSH, target: "push-token-123" }],
    createdAt: "2024-10-04T15:45:00Z",
    updatedAt: "2024-10-05T10:15:00Z",
  },
  {
    id: "4",
    uid: "user1",
    url: "https://wallish.com/ping",
    interval: 300,
    saveResponseOnError: true,
    notifyOnError: true,
    notifications: [
      { type: NotificationType.EMAIL, target: "user1@example.com" },
      { type: NotificationType.SMS, target: "+1234567890" },
    ],
    createdAt: "2024-10-01T12:00:00Z",
    updatedAt: "2024-10-02T08:00:00Z",
  },
];
export const fakePings1 = [
  {
    id: "ping1-21",
    pingConfigID: "1",
    url: "https://wallish.com",
    time: 100,
    responseCode: 200,
    createdAt: "2024-10-02T12:00:00Z",
  },
  {
    id: "ping1-1",
    pingConfigID: "1",
    url: "https://wallish.com",
    time: 100,
    responseCode: 200,
    createdAt: "2024-10-01T12:00:00Z",
  },
  {
    id: "ping1-2",
    pingConfigID: "1",
    url: "https://wallish.com",
    time: 200,
    responseCode: 500,
    errorResponse: "Internal Server Error",
    createdAt: "2024-10-01T13:00:00Z",
  },
  {
    id: "ping1-3",
    pingConfigID: "1",
    url: "https://wallish.com",
    time: 120,
    responseCode: 200,
    createdAt: "2024-10-01T14:00:00Z",
  },
  {
    id: "ping1-4",
    pingConfigID: "1",
    url: "https://wallish.com",
    time: 300,
    responseCode: 404,
    errorResponse: "Not Found",
    createdAt: "2024-10-01T15:00:00Z",
  },
  {
    id: "ping1-5",
    pingConfigID: "1",
    url: "https://wallish.com",
    time: 95,
    responseCode: 200,
    createdAt: "2024-10-01T16:00:00Z",
  },
];

export const fakePings2 = [
  {
    id: "ping2-1",
    pingConfigID: "2",
    url: "https://brownie.com",
    time: 80,
    responseCode: 200,
    createdAt: "2024-10-02T12:00:00Z",
  },
  {
    id: "ping2-2",
    pingConfigID: "2",
    url: "https://brownie.com",
    time: 150,
    responseCode: 503,
    errorResponse: "Service Unavailable",
    createdAt: "2024-10-02T13:00:00Z",
  },
  {
    id: "ping2-3",
    pingConfigID: "2",
    url: "https://brownie.com",
    time: 110,
    responseCode: 200,
    createdAt: "2024-10-02T14:00:00Z",
  },
  {
    id: "ping2-4",
    pingConfigID: "2",
    url: "https://brownie.com",
    time: 250,
    responseCode: 500,
    errorResponse: "Internal Server Error",
    createdAt: "2024-10-02T15:00:00Z",
  },
  {
    id: "ping2-5",
    pingConfigID: "2",
    url: "https://brownie.com",
    time: 90,
    responseCode: 200,
    createdAt: "2024-10-02T16:00:00Z",
  },
];

export const fakePings3 = [
  {
    id: "ping3-1",
    pingConfigID: "3",
    url: "https://allahuakbar.com",
    time: 130,
    responseCode: 200,
    createdAt: "2024-10-03T12:00:00Z",
  },
  {
    id: "ping3-2",
    pingConfigID: "3",
    url: "https://allahuakbar.com",
    time: 180,
    responseCode: 504,
    errorResponse: "Gateway Timeout",
    createdAt: "2024-10-03T13:00:00Z",
  },
  {
    id: "ping3-3",
    pingConfigID: "3",
    url: "https://allahuakbar.com",
    time: 100,
    responseCode: 200,
    createdAt: "2024-10-03T14:00:00Z",
  },
  {
    id: "ping3-4",
    pingConfigID: "3",
    url: "https://allahuakbar.com",
    time: 220,
    responseCode: 403,
    errorResponse: "Forbidden",
    createdAt: "2024-10-03T15:00:00Z",
  },
  {
    id: "ping3-5",
    pingConfigID: "3",
    url: "https://allahuakbar.com",
    time: 115,
    responseCode: 200,
    createdAt: "2024-10-03T16:00:00Z",
  },
];

export const fakePings4 = [
  {
    id: "ping4-21",
    pingConfigID: "4",
    url: "https://wallish.com/ping",
    time: 100,
    responseCode: 200,
    createdAt: "2024-10-02T12:00:00Z",
  },
  {
    id: "ping4-1",
    pingConfigID: "4",
    url: "https://wallish.com/ping",
    time: 100,
    responseCode: 200,
    createdAt: "2024-10-01T12:00:00Z",
  },
  {
    id: "ping4-2",
    pingConfigID: "4",
    url: "https://wallish.com/ping",
    time: 200,
    responseCode: 500,
    errorResponse: "Internal Server Error",
    createdAt: "2024-10-01T13:00:00Z",
  },
  {
    id: "ping4-3",
    pingConfigID: "4",
    url: "https://wallish.com/ping",
    time: 120,
    responseCode: 200,
    createdAt: "2024-10-01T14:00:00Z",
  },
  {
    id: "ping4-4",
    pingConfigID: "4",
    url: "https://wallish.com/ping",
    time: 300,
    responseCode: 404,
    errorResponse: "Not Found",
    createdAt: "2024-10-01T15:00:00Z",
  },
  {
    id: "ping4-5",
    pingConfigID: "4",
    url: "https://wallish.com/ping",
    time: 95,
    responseCode: 200,
    createdAt: "2024-10-01T16:00:00Z",
  },
];
