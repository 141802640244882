import React, { useEffect, useRef, useState } from "react";
import Globe from "react-globe.gl";
import { debounce } from "lodash";
const pingRequests = [
  {
    from: { lat: 37.7749, lng: -122.4194 }, // San Francisco, USA
    to: { lat: 51.5074, lng: -0.1278 }, // London, UK
  },
  {
    from: { lat: 34.0522, lng: -118.2437 }, // Los Angeles, USA
    to: { lat: 35.6895, lng: 139.6917 }, // Tokyo, Japan
  },
  {
    from: { lat: 48.8566, lng: 2.3522 }, // Paris, France
    to: { lat: -33.8688, lng: 151.2093 }, // Sydney, Australia
  },
  {
    from: { lat: 55.7558, lng: 37.6173 }, // Moscow, Russia
    to: { lat: -34.6037, lng: -58.3816 }, // Buenos Aires, Argentina
  },
  {
    from: { lat: 40.7128, lng: -74.006 }, // New York City, USA
    to: { lat: -22.9068, lng: -43.1729 }, // Rio de Janeiro, Brazil
  },
  {
    from: { lat: 39.9042, lng: 116.4074 }, // Beijing, China
    to: { lat: 30.0444, lng: 31.2357 }, // Cairo, Egypt
  },
  {
    from: { lat: 19.076, lng: 72.8777 }, // Mumbai, India
    to: { lat: 52.52, lng: 13.405 }, // Berlin, Germany
  },
  {
    from: { lat: 43.6532, lng: -79.3832 }, // Toronto, Canada
    to: { lat: -26.2041, lng: 28.0473 }, // Johannesburg, South Africa
  },
  {
    from: { lat: 25.2048, lng: 55.2708 }, // Dubai, UAE
    to: { lat: 1.3521, lng: 103.8198 }, // Singapore, Singapore
  },
  {
    from: { lat: 41.9028, lng: 12.4964 }, // Rome, Italy
    to: { lat: 37.5665, lng: 126.978 }, // Seoul, South Korea
  },
  {
    from: { lat: 41.8781, lng: -87.6298 }, // Chicago, USA
    to: { lat: 19.4326, lng: -99.1332 }, // Mexico City, Mexico
  },
  {
    from: { lat: 40.4168, lng: -3.7038 }, // Madrid, Spain
    to: { lat: 22.3193, lng: 114.1694 }, // Hong Kong, China
  },
  {
    from: { lat: 41.0082, lng: 28.9784 }, // Istanbul, Turkey
    to: { lat: 6.5244, lng: 3.3792 }, // Lagos, Nigeria
  },
  {
    from: { lat: 49.2827, lng: -123.1207 }, // Vancouver, Canada
    to: { lat: -33.4489, lng: -70.6693 }, // Santiago, Chile
  },
  {
    from: { lat: 13.7563, lng: 100.5018 }, // Bangkok, Thailand
    to: { lat: 59.9139, lng: 10.7522 }, // Oslo, Norway
  },
  {
    from: { lat: 38.7223, lng: -9.1393 }, // Lisbon, Portugal
    to: { lat: -37.8136, lng: 144.9631 }, // Melbourne, Australia
  },
  {
    from: { lat: 38.9072, lng: -77.0369 }, // Washington, D.C., USA
    to: { lat: -1.2921, lng: 36.8219 }, // Nairobi, Kenya
  },
  {
    from: { lat: 53.3498, lng: -6.2603 }, // Dublin, Ireland
    to: { lat: -36.8485, lng: 174.7633 }, // Auckland, New Zealand
  },
  {
    from: { lat: 60.1699, lng: 24.9384 }, // Helsinki, Finland
    to: { lat: 21.3069, lng: -157.8583 }, // Honolulu, USA
  },
  {
    from: { lat: 59.3293, lng: 18.0686 }, // Stockholm, Sweden
    to: { lat: -33.9249, lng: 18.4241 }, // Cape Town, South Africa
  },
];
type ArcDataType = {
  startLat: Number;
  startLng: Number;
  endLat: Number;
  endLng: Number;
  color: String;
};

const GlobeAnimation: React.FC = () => {
  const globeRef = useRef<any>(null);

  const [arcsData, setArcsData] = useState<ArcDataType[]>([]);

  const globeContainerRef = useRef<any>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const updateDimensions = () => {
    if (globeContainerRef.current) {
      setWidth(globeContainerRef.current.offsetWidth);
      setHeight(globeContainerRef.current.offsetHeight);
    }
  };
  useEffect(() => {
    // Set initial dimensions
    updateDimensions();

    // Update dimensions on window resize
    const handleResize = debounce(updateDimensions, 100);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const arcs = pingRequests.map((ping) => {
      return {
        startLat: ping.from.lat,
        startLng: ping.from.lng,
        endLat: ping.to.lat,
        endLng: ping.to.lng,
        color: "#f59e0b",
      };
    });

    setArcsData(arcs);
  }, []);

  useEffect(() => {
    const globe = globeRef.current;
    if (!globe) return;

    // Auto-rotate the globe
    globe.controls().autoRotate = true;
    globe.controls().autoRotateSpeed = 0.5;

    // Set initial point of view
    globe.pointOfView({ lat: 20, lng: 0, altitude: 2.5 });
  }, []);

  // Globe texture images
  const globeImageUrl =
    "//unpkg.com/three-globe/example/img/earth-blue-marble.jpg";
  const bumpImageUrl = "//unpkg.com/three-globe/example/img/earth-topology.png";

  return (
    <div
      ref={globeContainerRef}
      style={{ width: "100%", height: "100vh", alignContent: "center" }}
    >
      <Globe
        ref={globeRef}
        width={width}
        height={height}
        globeImageUrl={globeImageUrl}
        bumpImageUrl={bumpImageUrl}
        backgroundColor="rgba(0,0,0,0)" // Transparent background
        arcsData={arcsData}
        arcColor="color"
        arcDashLength={1}
        //arcDashGap={1}
        arcDashGap={() => Math.random()}
        arcDashAnimateTime={10000}
        arcDashInitialGap={0}
        arcsTransitionDuration={0}
      />
    </div>
  );
};

export default GlobeAnimation;
