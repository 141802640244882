import React from "react";
import { Outlet } from "react-router-dom";
import { useDarkMode } from "../hooks/useDarkMode";
import Navbar from "./Navbar";

const Layout: React.FC = () => {
  return (
    <div className="relative flex min-h-screen flex-col bg-background">
      <Navbar />
      <main className="flex-1">
        <div className="relative">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Layout;
