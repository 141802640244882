import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/auth";
import { doSignOut } from "../firebase/auth";
import { Button } from "./ui/Button";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "./ui/NavigationMenu";
import { Logo } from "./ui/Logo";
import { useDarkMode } from "../hooks/useDarkMode";
import { Toggle } from "./ui/Toggle";
import { SunIcon, MoonIcon } from "@radix-ui/react-icons";

const Navbar: React.FC = () => {
  const { userLoggedIn } = useAuth();
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  return (
    <header className="sticky top-0 z-50 w-full bg-background/95 border-b border-border/40 backdrop-blur-md supports-[backdrop-filter]:bg-background/60 shadow-sm">
      <div className="container mx-auto px-4 flex justify-between items-center py-3">
        {/* App Logo */}
        <Link
          to="/"
          className="text-2xl font-bold text-primary hover:text-primary-dark transition-colors flex  items-center"
        >
          <Logo className="w-10	h-10 mr-2" />
          PingPapi
        </Link>

        <NavigationMenu>
          <NavigationMenuList className="flex items-center gap-4">
            {/* Conditional Buttons */}
            {userLoggedIn ? (
              <>
                <NavigationMenuItem>
                  <Link to="/dashboard">
                    <Button variant="ghost">Dashboard</Button>
                  </Link>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <Button variant="ghost" onClick={doSignOut}>
                    Log Out
                  </Button>
                </NavigationMenuItem>
              </>
            ) : (
              <>
                <NavigationMenuItem>
                  <Link to="/login">
                    <Button variant="outline" className="ml-2">
                      Log In
                    </Button>
                  </Link>
                </NavigationMenuItem>
                <NavigationMenuItem>
                  <Link to="/register">
                    <Button variant="default" className="ml-2">
                      Sign Up
                    </Button>
                  </Link>
                </NavigationMenuItem>
              </>
            )}
            <NavigationMenuItem>
              <Toggle onClick={toggleDarkMode}>
                {isDarkMode ? <SunIcon /> : <MoonIcon />}
              </Toggle>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </header>
  );
};

export default Navbar;
