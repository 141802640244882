import { Controller } from "react-hook-form";
import {
  FormItem,
  FormLabel,
  FormControl,
  Input,
  FormMessage,
} from "../../../components";
export const JsonInputField = ({
  control,
  name,
  label,
}: {
  control: any;
  name: string;
  label: string;
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue=""
    rules={{
      validate: (value) => {
        try {
          JSON.parse(value); // Check if it's valid JSON
          return true;
        } catch {
          return "Invalid JSON format";
        }
      },
    }}
    render={({ field, fieldState }) => (
      <FormItem>
        <FormLabel className="font-bold">{label}</FormLabel>
        <FormControl>
          <Input placeholder="Enter JSON here..." type="text" {...field} />
        </FormControl>
        <FormMessage>{fieldState.error?.message}</FormMessage>
      </FormItem>
    )}
  />
);
