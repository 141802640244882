import axios from "axios";
import { getAuth } from "firebase/auth";
import PingyApiError from "./PingyApiError";

declare module "axios" {
  export interface AxiosRequestConfig {
    requiresAuth?: boolean; // Add the optional requiresAuth property
  }
}

// Create Axios instance for your backend API
const pingyClient = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add Axios request interceptor for handling authentication
pingyClient.interceptors.request.use(
  async (config) => {
    // Default requiresAuth to true unless explicitly set to false
    const requiresAuth = config.requiresAuth !== false;

    // If requiresAuth is true, add Firebase Auth token
    if (requiresAuth) {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const token = await user.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        throw new Error("User is not authenticated");
      }
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

export const handleError = (error: any): never => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // Handle response errors
      const status = error.response.status;
      const requestUrl = error.config?.url || "Unknown URL";
      const method = error.config?.method?.toUpperCase() || "UNKNOWN";

      switch (status) {
        case 400:
          console.error("Bad request: Please check your input");
          break;
        case 401:
          console.error("Unauthorized: Please log in");
          break;
        case 403:
          console.error("Forbidden: You do not have permission");
          break;
        case 500:
          console.error("Server error: Please try again later");
          break;
        default:
          console.error(`Error ${status}: ${error.response.statusText}`);
      }

      throw new PingyApiError(
        status,
        `Request failed with status code ${status}: ${error.response.statusText}`,
        requestUrl,
        method
      );
    } else if (error.request) {
      // Handle no response (network error)
      console.error("Network error: No response received");
      throw new PingyApiError(
        0,
        "Network error: No response received",
        error.config?.url || "Unknown URL",
        error.config?.method?.toUpperCase() || "UNKNOWN"
      );
    } else {
      // Handle Axios configuration or other errors
      console.error("Axios configuration error:", error.message);
      throw new PingyApiError(
        0,
        "Request configuration error",
        error.config?.url || "Unknown URL",
        error.config?.method?.toUpperCase() || "UNKNOWN"
      );
    }
  } else {
    // Handle non-Axios errors
    console.error("Unexpected error:", error);
    throw new PingyApiError(
      0,
      "An unexpected error occurred",
      "Unknown URL",
      "UNKNOWN"
    );
  }
};

// Export the Axios instance for your backend
export default pingyClient;
