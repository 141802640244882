import React from "react";
import { Link } from "react-router-dom";
import { Button, Logo } from "../components";

const NotFound = () => {
  return (
    <div className="container mx-auto px-4 py-8 flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold mb-4 text-center">Oops not found </h1>
      <p className="text-lg mt-4">
        The page you're looking for does not exist.
      </p>

      <Link
        to="/"
        className="text-2xl font-bold text-primary hover:text-primary-dark transition-colors flex  items-center"
      >
        <Logo className="w-10	h-10 mr-2" />
        <Button>Go Back to Home</Button>
      </Link>
    </div>
  );
};

export default NotFound;
