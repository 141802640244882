import { z } from "zod";
import { HttpMethod } from "../../../api/ping-config";

const EmailNotificationSchema = z.object({
  type: z.literal("EMAIL"),
  target: z
    .string()
    .email({ message: "Target must be a valid email address." }),
});

const WebhookNotificationSchema = z.object({
  type: z.literal("WEBHOOK"),
  target: z
    .string()
    .url({ message: "Target must be a valid URL for WEBHOOK type." }),
});

const SmsNotificationSchema = z.object({
  type: z.literal("SMS"),
  target: z
    .string()
    .min(2, { message: "Target must be at least 2 characters." }),
});

const PushNotificationSchema = z.object({
  type: z.literal("PUSH"),
  target: z
    .string()
    .min(2, { message: "Target must be at least 2 characters." }),
});

export const NotificationSchema = z.union([
  EmailNotificationSchema,
  WebhookNotificationSchema,
  SmsNotificationSchema,
  PushNotificationSchema,
]);

export const PingConfigSchema = z.object({
  url: z.string().url({ message: "URL must be a valid URL." }),
  interval: z.coerce
    .number()
    .positive({ message: "Interval must be a positive number." }),
  httpHeaders: z.record(z.string()).optional(),
  httpMethod: z.nativeEnum(HttpMethod).optional(),
  httpBody: z
    .string()
    .refine(
      (val) => {
        try {
          JSON.parse(val);
          return true;
        } catch {
          return false;
        }
      },
      { message: "Invalid JSON format" }
    )
    .optional(),
  saveResponseOnError: z.boolean(),
  notifyOnError: z.boolean(),
  notifications: z.array(NotificationSchema),
});
