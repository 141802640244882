export default class PingyApiError extends Error {
  status: number;
  requestUrl: string;
  method: string;

  constructor(
    status: number,
    message: string,
    requestUrl: string,
    method: string
  ) {
    super(message); // Call the parent constructor (Error) with the message
    this.status = status;
    this.requestUrl = requestUrl;
    this.method = method;
    this.name = 'PingyApiError'; // Custom error name for identification
  }
}
