import { Controller, useFormContext } from "react-hook-form";
import { NotificationSchema } from "../schema/PingConfigSchema";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
} from "@radix-ui/react-select";
import { Button } from "../../../components/ui/Button";
import {
  FormLabel,
  FormItem,
  FormControl,
  FormMessage,
} from "../../../components/ui/Form";
import { Input } from "../../../components/ui/Input";

interface NotificationsSectionProps {
  fields: { id: string; type: Notification["type"]; target: string }[];
  append: (value: Notification) => void;
  remove: (index: number) => void;
}
interface Notification {
  type: "EMAIL" | "WEBHOOK" | "SMS" | "PUSH";
  target: string;
}
export const NotificationsSection: React.FC<NotificationsSectionProps> = ({
  fields,
  append,
  remove,
}) => {
  const { control, watch, setValue, setError, clearErrors } = useFormContext();
  const type =
    (watch("newNotificationType") as Notification["type"]) || "EMAIL";
  const target = (watch("newNotificationTarget") as string) || "";

  const handleAddNotification = () => {
    // Validate new notification with NotificationSchema
    const result = NotificationSchema.safeParse({ type, target });

    if (!result.success) {
      // Show error messages for target if validation fails
      result.error.errors.forEach((err) => {
        if (err.path[0] === "target") {
          setError("newNotificationTarget", {
            message: err.message,
          });
        }
      });
      return;
    }

    // If validation passes, clear errors and append notification
    clearErrors(["newNotificationType", "newNotificationTarget"]);
    append({ type, target });

    // Reset form fields after adding
    setValue("newNotificationType", "EMAIL");
    setValue("newNotificationTarget", "");
  };

  return (
    <div className="space-y-6 p-4 rounded-md border shadow">
      <FormLabel className="text-lg font-semibold">Notifications</FormLabel>

      <div className="space-y-4">
        <Controller
          control={control}
          name="newNotificationType"
          defaultValue="EMAIL"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel className="font-bold">Type</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Notification Type</SelectLabel>
                      <SelectItem value="EMAIL">Email</SelectItem>
                      <SelectItem value="WEBHOOK">Webhook</SelectItem>
                      <SelectItem value="SMS">SMS</SelectItem>
                      <SelectItem value="PUSH">Push</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormControl>
              {/* Error message container with fixed height */}
              <div className="mt-1">
                {fieldState.error && (
                  <FormMessage>{fieldState.error.message}</FormMessage>
                )}
              </div>
            </FormItem>
          )}
        />

        <Controller
          control={control}
          name="newNotificationTarget"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel className="font-bold">Target</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter target"
                  {...field}
                  className="w-full"
                />
              </FormControl>
              {/* Error message container with fixed height */}
              <div className="mt-1">
                {fieldState.error && (
                  <FormMessage>{fieldState.error.message}</FormMessage>
                )}
              </div>
            </FormItem>
          )}
        />

        <Button
          type="button"
          onClick={handleAddNotification}
          className="w-full mt-2"
          variant="outline"
        >
          Add Notification
        </Button>
      </div>

      {/* Read-Only List of Notifications */}
      <div className="space-y-2">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="flex items-center justify-between p-2 border-b last:border-none"
          >
            <div>
              <span className="text-sm font-semibold">{field.type}</span>
              <span className="text-sm block">{field.target}</span>
            </div>
            <Button
              type="button"
              onClick={() => remove(index)}
              variant="destructive"
            >
              Remove
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};
