import {
  CheckCircledIcon,
  EnvelopeOpenIcon,
  ExclamationTriangleIcon,
} from '@radix-ui/react-icons';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Badge } from '../components/ui/Badge';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../components/ui/Card';
import { Logo } from '../components/ui/Logo';
import { useAuth } from '../context/auth';
import GlobeAnimation from './GlobeAnimation';

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();

  return (
    <>
      <div className="relative min-h-screen overflow-hidden">
        <GlobeAnimation />
        <div className="container absolute inset-0 flex flex-col justify-center items-center text-center text-white z-10 ">
          <h1 className="text-4xl font-extrabold mb-6 ">
            Uptime Monitoring Made Simple
          </h1>
          <p className="text-xl md:text-xl mb-10  max-w-xl ">
            Always in sync with your servers. Simple, real-time monitoring that
            notifies you whenever and wherever downtime strikes.
          </p>
          <button
            className="px-8 py-3 bg-gradient-to-r from-green-600 to-teal-600 rounded-lg text-lg font-semibold shadow-lg hover:shadow-xl hover:from-green-600 hover:to-teal-600 transition duration-300 transform hover:scale-105"
            onClick={() => navigate(userLoggedIn ? '/dashboard' : '/register')}
          >
            {userLoggedIn ? 'Dashboard' : 'Get Started'}
          </button>
        </div>
      </div>
      <HowItWorks />
      {!userLoggedIn && <FreePeriodSection />}
    </>
  );
};

const HowItWorks: React.FC = () => {
  const lines = () => (
    <div className="flex items-center justify-center">
      <div className="hidden md:block w-20 border-t-2 border-dotted border-gray-300"></div>
      <div className="md:hidden w-px h-12 border-l-2 border-dotted border-gray-300"></div>
    </div>
  );

  return (
    <div className="relative min-h-screen overflow-hidden flex flex-col items-center justify-center bg-secondary text-secondary-foreground p-8">
      <Logo className="w-24 h-24 mb-4" />
      <h2 className="text-3xl font-bold text-center mb-8">How It Works</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl">
        <Card className="text-center">
          <CardHeader>
            <div className="text-4xl text-green-500 mb-4">📡</div>
            <CardTitle className="text-2xl font-semibold">
              Set Up Your Ping
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              Connect your servers and configure your uptime checks with ease.
            </p>
          </CardContent>
        </Card>

        <Card className="text-center">
          <CardHeader>
            <div className="text-4xl text-green-500 mb-4">📊</div>
            <CardTitle className="text-2xl font-semibold">
              Monitor in Real-Time
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              See live status updates and track server health at a glance.
            </p>
          </CardContent>
        </Card>

        <Card className="text-center">
          <CardHeader>
            <div className="text-4xl text-green-500 mb-4">🔔</div>
            <CardTitle className="text-2xl font-semibold">
              Receive Alerts
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-600">
              Get notified instantly if any server goes down, with flexible
              alert settings.
            </p>
          </CardContent>
        </Card>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 max-w-6xl mx-auto mt-16">
        {/* Ping Sent Badge */}
        <div className="flex flex-col items-center relative flex-grow col-span-1">
          <div className="flex flex-col items-center space-y-2">
            <CheckCircledIcon className="w-6 h-6 animate-ping text-green-500" />
            <Badge className="bg-green-500 px-6 py-2 rounded-full text-white">
              <span>Ping Sent</span>
            </Badge>
          </div>
          <p className="text-sm text-gray-500 mt-2">
            Monitoring server health...
          </p>
        </div>

        {lines()}

        <div className="flex flex-col items-center relative flex-grow col-span-1">
          <div className="flex flex-col items-center space-y-2">
            <ExclamationTriangleIcon className="w-6 h-6 animate-bounce text-red-500" />
            <Badge className="bg-red-500 px-6 py-2 rounded-full text-white">
              <span>Error Detected</span>
            </Badge>
          </div>
          <p className="text-sm text-gray-500 mt-2">Server is down.</p>
        </div>
        {lines()}

        <div className="flex flex-col items-center relative flex-grow col-span-1">
          <div className="flex flex-col items-center space-y-2">
            <EnvelopeOpenIcon className="w-6 h-6 animate-fly-away text-blue-500" />
            <Badge className="bg-blue-500 px-6 py-2 rounded-full text-white">
              <span>Email Sent</span>
            </Badge>
          </div>
          <p className="text-sm text-gray-500 mt-2">
            Notifying user of downtime.
          </p>
        </div>
      </div>
    </div>
  );
};
const FreePeriodSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="relative min-h-screen overflow-hidden flex flex-col items-center justify-center p-8">
      <h2 className="text-3xl font-extrabold text-gray-800 mb-4">
        🎉 100 spots for free!
      </h2>
      <p className="text-lg text-gray-600 mb-8 max-w-xl mx-auto">
        We’re excited to launch! For a limited time, the first{' '}
        <span className="font-semibold text-green-600">100 customers</span> get
        complete access to our full monitoring suite at{' '}
        <span className="font-semibold">no cost</span> — including real-time
        notifications and insights.
      </p>
      <p className="text-lg text-gray-500 italic mb-12 max-w-lg mx-auto">
        This exclusive offer won’t last long. Secure your spot and enjoy Ping
        Papi’s full power, free for now!
      </p>
      <button
        className="px-8 py-3 bg-gradient-to-r from-green-600 to-teal-600 rounded-lg text-lg font-semibold text-white shadow-lg hover:shadow-xl hover:from-green-600 hover:to-teal-600 transition duration-300 transform hover:scale-105 mb-8"
        onClick={() => navigate('/register')}
      >
        Get Started for Free
      </button>
      <p className="text-sm text-gray-400 max-w-md text-center italic">
        Note: This is a limited-time offer available only to the first 100
        customers. We reserve the right to terminate free usage or modify the
        terms without prior notice.
      </p>
    </div>
  );
};

export default Landing;
