const Terms = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4 text-center">
        Terms and Conditions
      </h1>

      <p className="text-muted-foreground text-center mb-8">
        Last updated: October 29, 2024
      </p>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
        <p className="text-muted-foreground">
          By accessing or using our service, you agree to be bound by these
          Terms and Conditions. If you do not agree with any part of these
          terms, you may not access the service.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">2. User Responsibilities</h2>
        <p className="text-muted-foreground">
          You are responsible for maintaining the confidentiality of your
          account and password and for restricting access to your computer or
          device. You agree to accept responsibility for all activities that
          occur under your account or password.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">
          3. Modifications to the Service
        </h2>
        <p className="text-muted-foreground">
          We reserve the right to modify, suspend, or discontinue the service at
          any time without notice or liability to you. You acknowledge that the
          service may be interrupted for maintenance, updates, or due to factors
          beyond our control.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">
          4. Limitation of Liability
        </h2>
        <p className="text-muted-foreground">
          To the fullest extent permitted by law, in no event shall PingPapi be
          liable for any direct, indirect, incidental, special, consequential,
          or punitive damages, including but not limited to loss of profits,
          data, use, goodwill, or other intangible losses, resulting from:
        </p>
        <ul className="list-disc list-inside ml-4 text-muted-foreground">
          <li>
            Your access to or use of or inability to access or use the service;
          </li>
          <li>
            Any unauthorized access or use of our servers and/or any personal
            information stored therein;
          </li>
          <li>
            Any bugs, viruses, trojan horses, or the like that may be
            transmitted to or through our service by any third party;
          </li>
          <li>
            Any errors or omissions in any content or for any loss or damage
            incurred as a result of the use of any content posted, emailed,
            transmitted, or otherwise made available through the service.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">5. Governing Law</h2>
        <p className="text-muted-foreground">
          These Terms shall be governed and construed in accordance with the
          laws of Sweden, without regard to its conflict of law provisions.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">6. Changes to Terms</h2>
        <p className="text-muted-foreground">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will provide at
          least 30 days' notice prior to any new terms taking effect. By
          continuing to access or use our service after those revisions become
          effective, you agree to be bound by the revised terms.
        </p>
      </section>
    </div>
  );
};

export default Terms;
