import { DateTime, Duration } from "luxon";
import { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import { Ping } from "../../api/ping-config";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components";

import Spinner from "../../components/ui/Spinner";

const AverageResponseCard = ({ pings: initPings }: { pings: Ping[] }) => {
  const today = DateTime.local();
  const [startDate, setStartDate] = useState(
    today.minus(Duration.fromObject({ days: 6 }))
  );

  const [loading, setLoading] = useState(true);

  const [pings, setPings] = useState<Ping[]>([]);
  const [averageLatency, setAverageLatency] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [averageLatencyPerDay, setAverageLatencyPerDay] = useState<
    {
      date: string;
      averageLatency: number;
    }[]
  >([]);

  useEffect(() => {
    if (initPings.length === 0) {
      setLoading(false);
      return;
    }

    const effectivePings = initPings.filter((p) => {
      const createdAt = DateTime.fromISO(p.createdAt);

      return startDate <= createdAt && createdAt <= today;
    });

    if (effectivePings.length == 0) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setPings(effectivePings);

    const result = effectivePings.reduce(
      (acc, ping) => {
        const day = DateTime.fromISO(ping.createdAt).toFormat("yyyy-MM-dd"); // Group by day

        acc.totalLatency += ping.time / 1_000_000; // TODO should be made in backend when ping is done see todos.

        if (ping.errorResponse) {
          acc.errorCount += 1;
        }

        if (!acc.latencyPerDay[day]) {
          acc.latencyPerDay[day] = { totalLatency: 0, count: 0 };
        }
        acc.latencyPerDay[day].totalLatency += ping.time / 1_000_000; // TODO should be made in backend when ping is done see todos.
        acc.latencyPerDay[day].count += 1;

        return acc;
      },
      {
        totalLatency: 0,
        errorCount: 0,
        latencyPerDay: {} as {
          [key: string]: { totalLatency: number; count: number };
        },
      }
    );

    const averageLatency = result.totalLatency / effectivePings.length;
    setAverageLatency(averageLatency);

    setErrorCount(result.errorCount);

    const avgLatencyPerDayMap = Object.keys(result.latencyPerDay).reduce(
      (acc, day) => {
        acc[day] =
          result.latencyPerDay[day].totalLatency /
          result.latencyPerDay[day].count;
        return acc;
      },
      {} as { [key: string]: number }
    );

    const avgLatencyPerDay: { date: string; averageLatency: number }[] = [];
    let currentDate = startDate.startOf("day");
    const endDate = today.startOf("day");

    while (currentDate <= endDate) {
      const dateStr = currentDate.toFormat("yyyy-MM-dd");

      avgLatencyPerDay.push({
        date: dateStr,
        averageLatency: avgLatencyPerDayMap[dateStr] ?? 0,
      });

      currentDate = currentDate.plus({ days: 1 });
    }

    setAverageLatencyPerDay(avgLatencyPerDay);
    setLoading(false);
  }, [initPings, setStartDate]);
  if (loading) return <Spinner />;

  return (
    <Card className="flex flex-col md:max-w-lg" x-chunk="charts-01-chunk-1">
      <CardHeader className="flex flex-row items-center gap-4 space-y-0 pb-2 [&>div]:flex-1">
        <div>
          <CardDescription>Average latency</CardDescription>
          <CardTitle className="flex items-baseline gap-1 text-4xl tabular-nums">
            {Math.round(averageLatency)}
            <span className="text-sm font-normal tracking-normal text-muted-foreground">
              ms
            </span>
          </CardTitle>
        </div>
        <div>
          <CardDescription>Errors</CardDescription>
          <CardTitle className="flex items-baseline gap-1 text-4xl tabular-nums">
            {errorCount}
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="flex flex-1 items-center">
        <ChartContainer
          config={{
            averageLatency: {
              label: "Latency",
              color: "hsl(var(--chart-1))",
            },
          }}
          className="w-full"
        >
          <LineChart
            accessibilityLayer
            margin={{
              left: 14,
              right: 14,
              top: 10,
            }}
            data={averageLatencyPerDay}
          >
            <CartesianGrid
              strokeDasharray="4 4"
              vertical={false}
              stroke="hsl(var(--muted-foreground))"
              strokeOpacity={0.5}
            />
            <YAxis hide domain={["dataMin - 10", "dataMax + 10"]} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => {
                return new Date(value).toLocaleDateString("en-US", {
                  weekday: "short",
                });
              }}
            />
            <Line
              dataKey="averageLatency"
              type="monotone"
              fill="var(--color-averageLatency)"
              stroke="var(--color-averageLatency)"
              strokeWidth={2}
              dot={{
                fill: "var(--color-averageLatency)",
              }}
              activeDot={{
                fill: "var(--color-averageLatency)",
                stroke: "var(--color-averageLatency)",
                r: 4,
              }}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  indicator="line"
                  labelFormatter={(value) => {
                    return new Date(value).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    });
                  }}
                />
              }
              cursor={false}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

export default AverageResponseCard;
