import pingyClient, { handleError } from "../pingy-client";
import PingyApiError from "../PingyApiError";
import { Ping, PingConfig } from "./domain";
import {
  fakePingConfigs,
  fakePings1,
  fakePings2,
  fakePings3,
  fakePings4,
} from "./FakePingData";
import { CreatePingConfigRequest } from "./protocol";

const PingConfigApiContext = Object.seal(
  (() => {
    const domain = "pingy-api";
    const version = "v1";
    const apiUrl = `/${domain}/${version}`;
    return {
      domain,
      version,
      apiUrl,
    };
  })()
);

export const createPingConfig = async (
  request: CreatePingConfigRequest
): Promise<PingConfig> => {
  console.log("CONTEXTURL: ", PingConfigApiContext.apiUrl);
  const url = `${PingConfigApiContext.apiUrl}/ping-config`;
  console.log("URL ", url);

  try {
    const response = await pingyClient.post<PingConfig>(
      "/ping-config",
      JSON.stringify(request)
    );

    // Ensure the response is status 201
    if (response.status !== 201) {
      throw new PingyApiError(
        response.status,
        "Unexpected status code",
        url,
        "POST"
      );
    }

    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Won't be reached, but TypeScript needs it
  }
};

export const getUserPingConfigs = async (): Promise<PingConfig[]> => {
  const url = `${PingConfigApiContext.apiUrl}/ping-config`;

  if (process.env.MOCK_API) {
    return fakePingConfigs;
  }
  try {
    const response = await pingyClient.get<PingConfig[]>("/ping-config");

    // Ensure the response is status 200
    if (response.status !== 200) {
      throw new PingyApiError(
        response.status,
        "Unexpected status code",
        url,
        "GET"
      );
    }

    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Won't be reached, but TypeScript needs it
  }
};
export const getPingsForPingConfig = async (id: string): Promise<Ping[]> => {
  if (process.env.MOCK_API) {
    switch (id) {
      case "1":
        return fakePings1;
      case "2":
        return fakePings2;
      case "3":
        return fakePings3;
      case "4":
        return fakePings4;
    }
  }
  const url = `${PingConfigApiContext.apiUrl}/ping-config/${id}/pings`;

  try {
    const response = await pingyClient.get<Ping[]>(`/ping-config/${id}/pings`);

    // Ensure the response is status 200
    if (response.status !== 200) {
      throw new PingyApiError(
        response.status,
        "Unexpected status code",
        url,
        "GET"
      );
    }

    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Won't be reached, but TypeScript needs it
  }
};
export const deletePingConfigById = async (id: string): Promise<Ping[]> => {
  const url = `${PingConfigApiContext.apiUrl}/ping-config/${id}`;

  try {
    const response = await pingyClient.delete<Ping[]>(`/ping-config/${id}`);

    // Ensure the response is status 200
    if (response.status !== 200) {
      throw new PingyApiError(
        response.status,
        "Unexpected status code",
        url,
        "GET"
      );
    }

    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Won't be reached, but TypeScript needs it
  }
};
