import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import {
  Checkbox,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  FormItem,
  FormControl,
  FormLabel,
  FormDescription,
} from "../../../components";

export const CheckboxView = ({
  value,
  onChange,
  label,
  description,
  ...props
}: {
  value: boolean;
  onChange: (...event: any[]) => void;
  label: string;
  description: string;
  className?: string | undefined;
}) => {
  return (
    <FormItem {...props}>
      <FormControl>
        <Checkbox checked={value} onCheckedChange={onChange} />
      </FormControl>
      <FormLabel>
        <span className="inline">{label}</span>
        <HoverCard>
          <HoverCardTrigger asChild>
            <QuestionMarkCircledIcon className="w-4 h-4 inline ml-1" />
          </HoverCardTrigger>
          <HoverCardContent>
            <FormDescription>{description}</FormDescription>
          </HoverCardContent>
        </HoverCard>
      </FormLabel>
    </FormItem>
  );
};
