import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDRrAOT2nAX18TKI8WxqMSKBgOU1G0kGvg',
  authDomain: 'pingy-b8b98.firebaseapp.com',
  projectId: 'pingy-b8b98',
  storageBucket: 'pingy-b8b98.appspot.com',
  messagingSenderId: '245823380018',
  appId: '1:245823380018:web:2198d63dea6cbe9381ba2b',
  measurementId: 'G-06VDDQWT5M',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { analytics, app, auth };
