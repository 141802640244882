import { cn } from "../../lib/utils";
//TODO  Better logo looks FUCKED on darkmode.
export const Logo = (props: any) => {
  var className = cn("fill-foreground", props.className);
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 473 452"
      preserveAspectRatio="xMidYMid meet"
      className={className} // Spread props to accept external properties such as className, style, etc.
    >
      <g
        transform="translate(0.000000,452.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M3012 4239 c-46 -14 -108 -80 -123 -129 -26 -86 26 -215 101 -252 19
-9 80 -22 135 -27 280 -29 509 -143 726 -360 151 -152 251 -316 320 -524 38
-114 68 -161 119 -187 87 -44 185 -11 232 79 34 64 36 117 9 222 -100 388
-399 771 -762 974 -141 80 -292 142 -422 175 -124 31 -283 45 -335 29z m233
-115 c50 -8 126 -27 170 -42 104 -34 297 -132 408 -206 277 -186 506 -507 586
-823 27 -103 27 -143 0 -177 -49 -62 -96 -21 -144 127 -21 61 -53 144 -72 182
-23 48 -27 63 -13 46 25 -29 25 -21 0 28 -24 48 -138 201 -150 201 -4 0 -39
30 -77 68 -103 101 -218 202 -271 237 -50 34 -152 85 -170 85 -6 0 -12 3 -14
8 -9 20 -182 63 -308 76 -130 14 -135 15 -162 45 -38 42 -38 94 -1 133 32 34
79 36 218 12z"
        />
        <path
          d="M1760 4120 c-46 -8 -82 -33 -114 -78 -40 -58 -49 -125 -44 -324 l4
-178 -148 7 c-135 6 -148 5 -154 -12 -11 -28 9 -68 43 -90 18 -11 32 -23 33
-26 0 -3 -17 -13 -37 -23 -100 -47 -271 -176 -382 -287 -207 -209 -336 -428
-426 -723 -92 -305 -84 -677 21 -973 93 -262 205 -436 423 -653 142 -141 167
-162 286 -232 188 -111 376 -181 600 -225 132 -25 458 -25 575 1 605 132 1093
557 1283 1116 127 374 90 985 -74 1214 -41 57 -62 69 -73 40 -11 -29 -6 -61
25 -160 44 -144 90 -424 91 -564 2 -197 -48 -449 -119 -595 -14 -27 -33 -71
-44 -98 -10 -26 -54 -100 -98 -165 -96 -144 -289 -344 -401 -417 -184 -120
-384 -201 -620 -252 -114 -24 -394 -24 -530 1 -130 24 -346 98 -458 157 -299
158 -536 395 -676 676 -25 49 -48 103 -52 119 -4 16 -17 52 -29 79 -43 96 -85
340 -85 485 0 98 28 315 51 400 49 182 158 401 268 543 163 210 400 388 617
464 l109 39 552 -2 c361 -2 558 -6 571 -13 11 -6 37 -15 58 -20 22 -5 49 -13
62 -16 18 -6 22 -3 22 13 0 11 -6 22 -12 24 -7 3 7 19 31 36 l44 32 18 -29
c34 -56 86 -80 216 -101 220 -35 406 -215 488 -470 39 -121 80 -168 167 -191
87 -24 173 23 202 108 21 60 20 83 -4 176 -96 365 -415 682 -767 762 -59 14
-130 25 -158 25 -96 -1 -164 -52 -180 -137 -8 -46 -32 -53 -166 -53 l-109 0 0
220 c0 273 -9 302 -105 354 -39 20 -52 21 -400 22 -198 0 -376 -3 -395 -6z
m747 -133 c13 -13 18 -36 20 -95 2 -117 12 -109 -140 -116 -226 -10 -618 -16
-643 -10 -23 6 -24 10 -24 89 0 88 16 126 64 145 11 5 175 8 363 7 312 -2 344
-4 360 -20z m28 -391 c4 -30 4 -59 0 -65 -7 -12 -561 -14 -720 -2 l-90 6 -3
58 -3 57 405 0 405 0 6 -54z m737 -17 c206 -52 370 -168 503 -354 110 -155
188 -411 139 -459 -19 -19 -64 -21 -87 -3 -9 6 -30 54 -47 106 -49 152 -110
260 -192 341 -114 113 -226 178 -357 206 -138 29 -146 32 -164 63 -9 16 -17
43 -17 60 0 61 82 76 222 40z"
        />
        <path
          d="M2820 3769 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M1955 3201 c-271 -45 -470 -149 -665 -345 -175 -178 -288 -371 -347
-593 -31 -120 -42 -329 -24 -479 26 -220 156 -498 299 -638 48 -47 49 -50 55
-120 12 -151 76 -259 188 -315 53 -27 73 -31 139 -31 84 0 178 27 261 74 52
30 65 29 214 -16 77 -22 103 -20 226 24 l56 20 49 -25 c171 -86 313 -105 410
-55 110 58 175 168 182 311 l5 89 68 69 c123 123 217 294 266 484 26 103 28
123 27 305 0 210 -18 332 -65 441 -26 60 -46 72 -103 64 l-42 -7 33 -94 c56
-162 66 -234 60 -433 -4 -158 -8 -188 -37 -290 -17 -63 -43 -137 -57 -165 -32
-64 -141 -226 -151 -226 -5 0 -13 26 -20 59 -13 60 -46 116 -111 184 -57 60
-154 81 -259 56 -69 -17 -208 -84 -269 -130 l-53 -40 -28 19 c-67 48 -161 51
-247 7 l-47 -23 -61 46 c-215 163 -438 167 -552 12 -20 -27 -44 -78 -54 -112
-10 -34 -23 -64 -29 -66 -31 -10 -157 218 -200 365 -62 208 -65 437 -7 650 63
233 221 461 424 612 72 54 210 124 296 151 148 45 370 62 515 40 124 -20 370
-116 461 -180 16 -12 32 -21 34 -19 1 2 7 32 12 66 l10 62 -98 51 c-106 55
-164 77 -304 116 -75 21 -117 26 -250 29 -88 2 -182 0 -210 -4z m-257 -1783
c59 -22 170 -94 186 -120 8 -13 7 -21 -6 -30 -8 -7 -19 -30 -22 -50 l-7 -38
-52 0 c-69 0 -97 -19 -97 -66 0 -26 8 -44 29 -65 25 -24 37 -29 80 -29 l50 0
12 -42 c7 -27 8 -48 2 -58 -5 -8 -53 -37 -105 -64 -85 -43 -104 -49 -156 -49
-145 2 -215 109 -216 333 -2 259 100 352 302 278z m1048 16 c19 -9 47 -31 63
-50 65 -74 93 -266 51 -349 -11 -21 -18 -41 -15 -43 3 -3 5 0 5 6 0 7 5 12 10
12 14 0 3 -44 -26 -105 -38 -81 -117 -110 -233 -85 -79 17 -217 91 -214 116 6
60 17 104 27 105 6 0 32 0 58 -1 30 -1 61 6 88 20 37 19 41 24 38 53 -5 48
-57 79 -127 75 l-54 -3 -20 57 c-20 57 -20 58 -1 79 21 23 205 115 244 122 50
9 73 7 106 -9z m-535 -137 c43 -28 82 -106 82 -162 0 -53 -42 -128 -86 -156
-63 -38 -162 -18 -205 41 -68 91 -38 230 59 278 57 28 107 28 150 -1z m-74
-467 c23 0 61 7 84 16 40 17 41 17 67 -9 14 -15 23 -29 20 -32 -12 -12 -215
-17 -272 -6 -33 6 -61 13 -63 15 -3 2 0 13 6 24 10 18 13 18 63 6 29 -8 72
-14 95 -14z"
        />
        <path
          d="M3112 3125 c-171 -87 -211 -329 -75 -461 83 -81 203 -102 300 -51 76
39 117 80 143 139 44 101 20 213 -66 307 -51 57 -105 83 -184 88 -56 4 -73 1
-118 -22z m161 -119 c69 -28 118 -118 98 -179 -13 -38 -65 -91 -99 -102 -36
-11 -127 21 -156 55 -61 73 -32 186 59 226 39 17 58 17 98 0z"
        />
        <path
          d="M1705 2876 c-77 -26 -132 -54 -169 -86 -33 -29 -37 -38 -34 -74 2
-31 9 -44 29 -55 24 -13 31 -12 95 21 39 20 99 44 134 53 76 20 100 38 100 75
0 63 -73 94 -155 66z"
        />
        <path
          d="M2443 2860 c-28 -26 -30 -71 -4 -94 10 -10 48 -24 83 -32 34 -8 91
-30 126 -49 96 -53 142 -44 142 29 0 97 -276 214 -347 146z"
        />
        <path
          d="M1630 2571 c-182 -59 -271 -242 -197 -405 28 -61 89 -125 145 -152
59 -29 161 -31 227 -4 129 52 208 228 164 361 -23 67 -84 135 -151 168 -66 32
-146 46 -188 32z m63 -132 c21 -23 27 -39 27 -72 -1 -45 -11 -63 -50 -90 -63
-43 -150 4 -150 82 0 31 7 49 30 75 48 54 95 55 143 5z m120 -230 c36 -39 34
-61 -7 -97 -40 -35 -66 -33 -96 6 -28 34 -25 77 5 108 31 31 57 26 98 -17z"
        />
        <path
          d="M2515 2567 c-129 -43 -207 -148 -207 -282 1 -116 59 -216 155 -268
34 -18 58 -22 127 -22 74 0 91 3 131 27 58 34 115 98 141 155 28 63 22 179
-12 246 -28 56 -86 112 -137 133 -50 21 -150 26 -198 11z m96 -126 c51 -51 36
-131 -31 -166 -78 -40 -150 7 -150 98 0 81 122 127 181 68z m93 -232 c31 -37
33 -58 5 -93 -40 -51 -119 -17 -119 52 0 32 36 72 66 72 13 0 33 -13 48 -31z"
        />
        <path
          d="M1817 1902 c-37 -41 -15 -103 58 -171 64 -59 143 -93 231 -99 87 -6
155 13 244 66 136 82 176 222 63 222 -26 0 -37 -9 -66 -49 -90 -125 -293 -141
-392 -31 -78 86 -105 98 -138 62z"
        />
        <path
          d="M812 1218 c25 -39 109 -131 130 -144 7 -4 -12 19 -42 52 -30 32 -68
75 -83 94 l-28 35 23 -37z"
        />
      </g>
    </svg>
  );
};
