export enum NotificationType {
  EMAIL = "EMAIL",
  WEBHOOK = "WEBHOOK",
  SMS = "SMS",
  PUSH = "PUSH",
}
export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}
export interface Notification {
  type: NotificationType;
  target: string;
}

export interface PingConfig {
  id: string;
  uid: string;
  url: string;
  interval: number;
  httpHeaders?: Record<string, string>;
  httpMethod?: HttpMethod;
  httpBody?: any;
  saveResponseOnError: boolean;
  notifyOnError: boolean;
  notifications: Notification[];
  createdAt: string;
  updatedAt?: string;
}

export interface Ping {
  id: string;
  pingConfigID: string;
  url: string;
  time: number;
  responseCode: number;
  errorResponse?: string;
  createdAt: string;
}
