import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import "./App.css";
import Layout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import { Toaster } from "./components/ui/Toaster";
import { AuthProvider } from "./context/auth";
import Dashboard from "./pages/dashboard";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Terms from "./pages/Terms";
import NotFound from "./pages/NotFoud";

const App = () => {
  //       <div className="w-full h-screen flex flex-col">{routesElement}</div>

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Public Routes */}
            <Route index element={<Landing />} />

            {/* Protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="dashboard" element={<Dashboard />} />
            </Route>

            {/* Login Route */}
            <Route path="login" element={<Login />} />

            {/* Register Route */}
            <Route path="register" element={<Register />} />
            <Route path="terms" element={<Terms />} />
            {/* Catch-All Route for 404 */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
      <Toaster />
    </AuthProvider>
  );
};

export default App;
