import { ChevronDownIcon } from "@radix-ui/react-icons";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { Ping } from "../../api/ping-config";
import {
  Badge,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components";

const Row = ({ errorPing }: { errorPing: Ping }) => {
  const [expanded, setExpanded] = useState(false);

  const errorTime = DateTime.fromISO(errorPing.createdAt);

  return (
    <Collapsible open={expanded} onOpenChange={setExpanded} asChild>
      <>
        <TableRow className="bg-accent">
          <TableCell>
            <Badge className="text-xs" variant="secondary">
              {errorPing.responseCode}
            </Badge>
          </TableCell>
          <TableCell className="hidden sm:table-cell">
            {errorPing.time}
          </TableCell>
          <TableCell>{errorTime.toFormat("yyyy-MM-hh")}</TableCell>
          <TableCell>{errorTime.toFormat("HH:mm")}</TableCell>
          <TableCell>
            <CollapsibleTrigger asChild>
              <ChevronDownIcon
                className="relative cursor-pointer top-[1px] ml-1 h-3 w-3 transition duration-300 data-[state=open]:rotate-180"
                aria-hidden="true"
              />
            </CollapsibleTrigger>
          </TableCell>
        </TableRow>

        <CollapsibleContent asChild>
          <TableRow className="bg-accent">
            <TableCell colSpan={5} className="bg-muted">
              <div className="p-4 rounded-lg w-full">
                <div className="mb-2">
                  <h4 className="font-bold text-lg">Details</h4>
                  <p>
                    <strong>Status:</strong> {errorPing.responseCode}
                  </p>
                  <p>
                    <strong>Response time (ms):</strong> {errorPing.time}
                  </p>
                  <p>
                    <strong>Date:</strong> {errorTime.toFormat("yyyy-MM-dd")}
                  </p>
                  <p>
                    <strong>Time:</strong> {errorTime.toFormat("HH:mm:ss")}
                  </p>
                </div>

                <div className="mt-4">
                  <h4 className="font-bold text-lg mb-2">Response</h4>
                  <pre className="p-4 bg-gray-800 text-white text-sm rounded-md overflow-auto max-h-64">
                    <code>{errorPing.errorResponse}</code>
                  </pre>
                </div>
              </div>
            </TableCell>
          </TableRow>
        </CollapsibleContent>
      </>
    </Collapsible>
  );
};

const ErrorTableCard = ({ pings: initPings }: { pings: Ping[] }) => {
  const pings = useMemo(() => {
    if (!initPings) {
      return [];
    }

    return initPings
      .filter((p) => p.responseCode <= 199 || p.responseCode >= 300)
      .sort(
        (a, b) =>
          DateTime.fromISO(b.createdAt).toMillis() -
          DateTime.fromISO(a.createdAt).toMillis()
      );
  }, [initPings]);

  return (
    <Card x-chunk="dashboard-05-chunk-3">
      <CardHeader className="px-7">
        <CardTitle>Errors</CardTitle>
        <CardDescription>Recent errors from your pings.</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Status</TableHead>
              <TableHead className="hidden sm:table-cell">
                Response (ms)
              </TableHead>
              <TableHead>Date</TableHead>
              <TableHead>Time</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {pings.length > 0 ? (
              pings.map((p) => <Row errorPing={p} />)
            ) : (
              <div>Such empty</div>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default ErrorTableCard;
