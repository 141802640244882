import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/auth';

const ProtectedRoute: React.FC = () => {
  const { userLoggedIn } = useAuth();

  const location = useLocation();

  if (!userLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
