import { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Button } from "../components/ui/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/Card";
import { Input } from "../components/ui/Input";
import { useAuth } from "../context/auth";
import {
  doSignInWithEmailAndPassword,
  doSignInWithGoogle,
} from "../firebase/auth";

const Login = () => {
  const { userLoggedIn } = useAuth();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const from = (location.state as { from?: Location })?.from?.pathname || "/";

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      setErrorMessage("");
      try {
        await doSignInWithEmailAndPassword(email, password);
      } catch (error) {
        setErrorMessage("Failed to sign in. Please check your credentials.");
        setIsSigningIn(false);
      }
    }
  };

  const onGoogleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      setErrorMessage("");
      try {
        await doSignInWithGoogle();
      } catch (error) {
        setErrorMessage("Google sign-in failed. Please try again.");
        setIsSigningIn(false);
      }
    }
  };

  if (userLoggedIn) return <Navigate to={from} replace={true} />;

  return (
    <div className="container flex justify-center items-center">
      <Card className="w-full max-w-md p-6 bg-card text-foreground shadow-lg rounded-lg">
        <CardHeader>
          <CardTitle className="text-center text-xl font-semibold sm:text-2xl">
            Log in into PingPapi
          </CardTitle>
          <CardDescription className="text-center text-muted-foreground">
            Log in with your credentials below.
          </CardDescription>
        </CardHeader>

        {errorMessage && (
          <div className="px-4 py-3 mb-4 text-sm font-semibold text-destructive-foreground bg-destructive rounded-lg">
            {errorMessage}
          </div>
        )}

        <CardContent>
          <form onSubmit={onSubmit} className="space-y-4">
            <div>
              <label className="text-sm font-bold text-muted-foreground">
                Email
              </label>
              <Input
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1"
                placeholder="you@example.com"
              />
            </div>

            <div>
              <label className="text-sm font-bold text-muted-foreground">
                Password
              </label>
              <Input
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1"
                placeholder="Your secure password"
              />
            </div>

            <Button
              type="submit"
              disabled={isSigningIn}
              className="w-full bg-primary text-primary-foreground hover:bg-primary/90"
            >
              {isSigningIn ? "Login in" : "Log In"}
            </Button>
          </form>

          <p className="text-center text-sm mt-4 text-muted-foreground">
            Don't have an account?{" "}
            <Link
              to="/register"
              className="hover:underline font-bold text-primary"
            >
              Sign up
            </Link>
          </p>

          <div className="flex items-center my-4">
            <hr className="w-full border-border" />
            <span className="px-2 font-bold text-muted-foreground">OR</span>
            <hr className="w-full border-border" />
          </div>
        </CardContent>

        <CardFooter>
          <Button
            disabled={isSigningIn}
            onClick={onGoogleSignIn}
            variant="outline"
            className="w-full flex items-center justify-center gap-x-2 border border-border text-foreground"
          >
            <svg
              className="w-5 h-5"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_17_40)">
                <path
                  d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z"
                  fill="#4285F4"
                />
                <path
                  d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z"
                  fill="#34A853"
                />
                <path
                  d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z"
                  fill="#FBBC04"
                />
                <path
                  d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z"
                  fill="#EA4335"
                />
              </g>
              <defs>
                <clipPath id="clip0_17_40">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {isSigningIn ? "Signing In..." : "Continue with Google"}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Login;
