"use client";

import { useEffect, useState } from "react";
import {
  deletePingConfigById,
  getPingsForPingConfig,
  getUserPingConfigs,
  Ping,
  PingConfig,
} from "../../api/ping-config";
import { Button } from "../../components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/DropdownMenu";
import AverageResponseCard from "./AverageResponseCard";
import ErrorTableCard from "./ErrorTableCard";

import { TrashIcon } from "@radix-ui/react-icons";
import { Plus } from "lucide-react";
import CreatePingConfigForm from "./forms/CreatePingConfigForm";
import CreatePingConfigDialog from "./CreatePingConfigDialog";
import { useAuth } from "../../context/auth";

export const Dashboard = () => {
  const [pingConfigs, setPingConfigs] = useState<PingConfig[]>([]);
  const [selectedPingConfig, setSelectedPingConfig] = useState<PingConfig>();
  const [pingsByConfig, setPingsByConfig] = useState<Record<string, Ping[]>>(
    {}
  );

  const { currentUser } = useAuth();

  useEffect(() => {
    getUserPingConfigs().then((data) => {
      setPingConfigs(data);
      if (data.length > 0) setSelectedPingConfig(data[0]);
    });
  }, []);

  useEffect(() => {
    if (!selectedPingConfig) return;
    getPingsForPingConfig(selectedPingConfig.id).then((data) => {
      setPingsByConfig((prev) => ({ ...prev, [selectedPingConfig.id]: data }));
    });
  }, [selectedPingConfig]);

  return (
    <div className="chart-wrapper mx-auto flex max-w-6xl flex-col flex-wrap items-start justify-center gap-6 sm:flex-row ">
      {selectedPingConfig ? (
        <>
          <div className="flex w-full	space-x-4">
            <PingConfigDropdown
              onChange={(pingConfig: string) => {
                console.log("ping config selected=", pingConfig);
                var selectedPingConfig = pingConfigs.find(
                  (config) => config.id === pingConfig
                );
                if (selectedPingConfig)
                  setSelectedPingConfig(selectedPingConfig);
              }}
              selected={selectedPingConfig}
              pingConfigs={pingConfigs}
            />
            <CreateNewConfigButton />
            <DeleteCurrentConfigButton selected={selectedPingConfig} />
          </div>
          <div className="grid w-full gap-6">
            <AverageResponseCard
              pings={pingsByConfig[selectedPingConfig?.id] ?? []}
            />
            <ErrorTableCard
              pings={(pingsByConfig[selectedPingConfig?.id] ?? [])?.filter(
                (ping) => ping.responseCode <= 199 || ping.responseCode >= 300
              )}
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-start pt-5 text-center gap-2 w-full">
          <h2 className="text-2xl font-semibold">
            Welcome {currentUser?.displayName}!
          </h2>
          <p className="text-gray-600 mt-2">
            Start monitoring your services by creating a new Ping configuration.
          </p>
          <CreatePingConfigForm
            inDialog={false}
            onSubmitCallback={setSelectedPingConfig}
          />
        </div>
      )}
    </div>
  );
};

const DeleteCurrentConfigButton = ({ selected }: { selected: PingConfig }) => {
  return (
    <Button variant="outline" onClick={() => deletePingConfigById(selected.id)}>
      <TrashIcon /> Delete Ping Config
    </Button>
  );
};
const PingConfigDropdown = ({
  onChange,
  selected,
  pingConfigs,
}: {
  onChange: (value: string) => void;
  selected: PingConfig;
  pingConfigs: PingConfig[];
}) => {
  const currentDomain = extractDomain(selected.url);
  const groupedPingConfigs = groupByDomain(pingConfigs);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">{selected.url}</Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Current Config</DropdownMenuLabel>
        <DropdownMenuItem>
          {selected.url}{" "}
          {
            // TOdO THeSE are haccky.
          }
          <Button
            variant="ghost"
            onClick={() => deletePingConfigById(selected.id)}
          >
            <TrashIcon />
          </Button>
        </DropdownMenuItem>

        {/* Actions */}
        <DropdownMenuItem>See All Configs</DropdownMenuItem>
        {
          // <DropdownMenuItem>Create New Config</DropdownMenuItem>
        }

        {/* Divider */}
        <DropdownMenuSeparator />

        {groupedPingConfigs[currentDomain]?.filter((c) => c.id != selected.id)
          .length > 0 && (
          <>
            <DropdownMenuLabel>Other domain configs</DropdownMenuLabel>
            {groupedPingConfigs[currentDomain]
              .filter((c) => c.id != selected.id)
              .map((config) => (
                <DropdownMenuItem key={config.id}>
                  <div className="flex justify-between items-center w-full">
                    <span onClick={() => onChange(config.id)}>
                      {config.url}
                    </span>
                    <Button
                      variant="ghost"
                      onClick={() => deletePingConfigById(config.id)}
                    >
                      <TrashIcon />
                    </Button>
                  </div>
                </DropdownMenuItem>
              ))}
            <DropdownMenuSeparator />
          </>
        )}

        {/* Recent Configs
        {recentConfigs.length > 0 && (
          <>
            <DropdownMenuLabel>Recent Configs</DropdownMenuLabel>
            {recentConfigs.map((config) => (
              <DropdownMenuItem
                key={config.id}
                onClick={() => onSelectConfig(config)}
              >
                {config.name}
              </DropdownMenuItem>
            ))}
          </>
        )}
        */}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
const CreateNewConfigButton = ({}: {}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && <CreatePingConfigDialog onClose={() => setOpen(false)} />}
      <Button variant="outline" onClick={() => setOpen(true)}>
        <Plus /> Create New Config
      </Button>
    </>
  );
};

const extractDomain = (url: string): string => {
  try {
    const { hostname } = new URL(url);
    return hostname;
  } catch (error) {
    return url;
  }
};

const groupByDomain = (pingConfigs: PingConfig[]) => {
  return pingConfigs.reduce((acc, pingConfig) => {
    const domain = extractDomain(pingConfig.url);
    if (!acc[domain]) {
      acc[domain] = [];
    }
    acc[domain].push(pingConfig);
    return acc;
  }, {} as Record<string, PingConfig[]>);
};

export default Dashboard;
