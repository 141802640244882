import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  createPingConfig,
  CreatePingConfigRequest,
  HttpMethod,
  PingConfig,
} from "../../../api/ping-config";
import {
  Button,
  DialogFooter,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components";

import { toast } from "../../../hooks/useToast";
import { CheckboxView, JsonInputField } from "../fields";
import { PingConfigSchema } from "../schema/PingConfigSchema";
import { NotificationsSection } from "./NotificationSection";
import HeadersField from "../fields/HeadersField";

const CreatePingConfigForm = ({
  onSubmitCallback,
  inDialog = false,
}: {
  onSubmitCallback?: (pingConfig: PingConfig) => void;
  inDialog?: boolean;
}) => {
  const form = useForm<z.infer<typeof PingConfigSchema>>({
    resolver: zodResolver(PingConfigSchema),
    defaultValues: {
      url: "",
      interval: 5,
      httpHeaders: {},
      httpMethod: HttpMethod.GET,
      httpBody: "",
      saveResponseOnError: false,
      notifyOnError: false,
      notifications: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "notifications",
  });

  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  const onSubmit = async (data: z.infer<typeof PingConfigSchema>) => {
    try {
      const createPingConfigRequest: CreatePingConfigRequest = {
        url: data.url,
        interval: data.interval * 60,
        httpHeaders: data.httpHeaders,
        httpMethod: data.httpMethod,
        saveResponseOnError: data.saveResponseOnError,
        notifyOnError: data.notifyOnError,
        notifications: data.notifications as any,
      };
      if (data.httpBody) {
        createPingConfigRequest.httpBody = JSON.parse(data.httpBody);
      }
      console.log("TEST", createPingConfigRequest);
      const response = await createPingConfig(createPingConfigRequest);
      if (onSubmitCallback) {
        onSubmitCallback(response);
      }
      toast({
        title: "Ping Configuration Created Successfully!",
        description: (
          <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
            <code className="text-white">
              {JSON.stringify(response, null, 2)}
            </code>
          </pre>
        ),
      });
    } catch (error) {
      toast({
        title: "Error submitting form",
        description: "Something went wrong. Please try again.",
      });
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={`mx-auto p-4 shadow-md rounded-lg text-left  ${
          inDialog
            ? "max-w-2xl space-y-6 bg-card"
            : "max-w-3xl w-full space-y-4"
        }`}
      >
        {/* URL and Interval Fields */}
        <FormField
          control={form.control}
          name="url"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-bold">URL</FormLabel>
              <FormControl>
                <Input placeholder="Enter URL" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="interval"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-bold">
                Ping Interval (minutes)
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter interval in minutes"
                  type="number"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* HTTP Headers Field */}
        <HeadersField />

        {/* HTTP Method Field */}
        <Controller
          control={form.control}
          name="httpMethod"
          defaultValue={HttpMethod.GET}
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel className="font-bold">Http Method</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger>
                    <SelectValue placeholder="Http Method" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Http Method</SelectLabel>
                      <SelectItem value={HttpMethod.GET}>GET</SelectItem>
                      <SelectItem value={HttpMethod.POST}>POST</SelectItem>
                      <SelectItem value={HttpMethod.PUT}>PUT</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormControl>
              {/* Error message container with fixed height */}
              <div className="mt-1">
                {fieldState.error && (
                  <FormMessage>{fieldState.error.message}</FormMessage>
                )}
              </div>
            </FormItem>
          )}
        />

        {/* HTTP Body Field */}
        <JsonInputField
          control={form.control}
          name="httpBody"
          label="HTTP Body"
        />

        {/* Save Response on Error */}
        <FormField
          control={form.control}
          name="saveResponseOnError"
          render={({ field }) => (
            <CheckboxView
              className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 shadow"
              label={"Save response on error"}
              description={
                "Save the response to the failed request to the endpoint."
              }
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        {/* Notify On Error with Conditional Notifications */}
        <FormField
          control={form.control}
          name="notifyOnError"
          render={({ field }) => (
            <CheckboxView
              className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 shadow"
              label={"Notify on error"}
              description={"A notification will be sent when a request fails."}
              value={field.value}
              onChange={(checked) => {
                field.onChange(checked);
                setNotificationsEnabled(checked === true);
              }}
            />
          )}
        />

        {/* Notifications Section (Only visible if Notify on Error is checked) */}
        {notificationsEnabled && (
          <NotificationsSection
            fields={fields}
            append={append}
            remove={remove}
          />
        )}
        {inDialog ? (
          <DialogFooter>
            {/* Submit Button */}
            <Button
              type="submit"
              className="w-full bg-primary text-primary-foreground hover:bg-primary/90"
            >
              Create Configuration
            </Button>
          </DialogFooter>
        ) : (
          <Button
            type="submit"
            className="w-full bg-primary text-primary-foreground hover:bg-primary/90"
            onClick={() => onSubmit(form.getValues())}
          >
            Create Configuration
          </Button>
        )}
      </form>
    </Form>
  );
};

export default CreatePingConfigForm;
