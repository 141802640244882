import { useEffect, useState } from 'react';

export const useDarkMode = () => {
  // State to track the dark mode preference
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check for user preference in localStorage
    const storedPreference = localStorage.getItem('theme');
    if (storedPreference) {
      return storedPreference === 'dark';
    }
    // If no preference is stored, follow system preference
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  // Update the HTML element class based on the mode
  useEffect(() => {
    const updateHtmlClass = (isDarkMode: boolean) => {
      if (isDarkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    };

    // Listen for system preference changes if user hasn't set a manual preference
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleSystemChange = (e: MediaQueryListEvent) => {
      if (!localStorage.getItem('theme')) {
        setIsDarkMode(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleSystemChange);

    // Apply the initial mode
    updateHtmlClass(isDarkMode);

    // Clean up the listener on unmount
    return () => mediaQuery.removeEventListener('change', handleSystemChange);
  }, [isDarkMode]);

  // Function to toggle dark mode manually
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('theme', newMode ? 'dark' : 'light');
      return newMode;
    });
  };

  return { isDarkMode, toggleDarkMode };
};
