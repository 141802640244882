import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../components";
import CreatePingConfigForm from "./forms";

const CreatePingConfigDialog = ({ onClose }: { onClose: () => void }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      modal
      open
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        }
      }}
    >
      <DialogContent
        className="max-w-3xl sm:max-w-md min-w-[420px] max-h-[80vh] overflow-y-auto"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>Create Ping Config</DialogTitle>
          <DialogDescription>
            Create a new ping config to start monitoring a new endpoint.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2 ">
          <CreatePingConfigForm inDialog={true} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreatePingConfigDialog;
