import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import {
  FormLabel,
  FormItem,
  FormControl,
  Input,
  FormMessage,
  Button,
} from "../../../components";

const HeadersField = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "httpHeaders", // The name must match your schema
  });

  const handleAddHeader = () => {
    append({ key: "", value: "" }); // Add an empty key-value pair
  };

  return (
    <div className="space-y-4 flex flex-col items-start">
      <FormLabel className="text-lg font-semibold">HTTP Headers</FormLabel>
      {fields.map((field, index) => (
        <div key={field.id} className="flex items-center space-x-4">
          <Controller
            control={control}
            name={`httpHeaders.${index}.key`} // Access key field
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Header Key" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Controller
            control={control}
            name={`httpHeaders.${index}.value`} // Access value field
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Header Value" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="button"
            onClick={() => remove(index)}
            variant="destructive"
          >
            Remove
          </Button>
        </div>
      ))}
      <Button type="button" onClick={handleAddHeader} variant="outline">
        Add Header
      </Button>
    </div>
  );
};

export default HeadersField;
